<template>
     <section class="page_title big  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Recent from the Blogs</h6>
                           <h1>  Latest Blogs</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">Media</a></li>
                              <li class="bread_crumb-item active">Blogs</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">
                <div class="featured_post">
                  <div class="row">
                     <!---------row----------->


                     <div class="col-lg-4">

                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/8081.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/8081.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://www.cifor.org/knowledge/8081/" target="_blank">Impacts of the COVID-19 pandemic on the Democratic Republic of Congo’s charcoal value chains: the case of Kisangani</a></h2>
                           <ul>
                   
                              <li><span class="linearicons-user"></span>Center for International Forestry Research</li>
                           </ul>
                           <p>The National Agency for Food and Drug Administration and Control (NAFDAC) says it has approved two herbal drugs to undergo clinical trials for the treatment of COVID-19.A statement on Sunday by the agency Resident Media Consultant, Mr Olusayo Akintola.</p>
                           <a href="https://www.cifor.org/knowledge/8081/" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>

                     <div class="col-lg-4">

                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/IMG_2362.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/IMG_2362.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://www.unicef.org/senegal/en/press-releases/senegal-strongly-committed-accelerating-vaccination-against-covid-19-throughout" target="_blank">Senegal strongly committed to accelerating vaccination against Covid-19 throughout the country</a></h2>
                           <ul>
                             
                              <li><span class="linearicons-user"></span>Lalaina F. Andriamasinoro</li>
                           </ul>
                           <p>From May 9 to 11, the CoVDP delegation conducted a series of meetings with all stakeholders in Senegal to understand immunization bottlenecks and explore ways in which the CoVDP can help Senegal accelerate its vaccination coverage.</p>
                           <a href="https://www.unicef.org/senegal/en/press-releases/senegal-strongly-committed-accelerating-vaccination-against-covid-19-throughout" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>
                     
                     <div class="col-lg-4">

                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/reversing-adverse-effects-covid-19-pandemic-democratic-republic-congo-v2-1140x500.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/reversing-adverse-effects-covid-19-pandemic-democratic-republic-congo-v2-1140x500.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://blogs.worldbank.org/africacan/reversing-adverse-effects-covid-19-pandemic-democratic-republic-congo" target="_blank">Reversing the adverse effects of the COVID-19 pandemic in the Democratic Republic of Congo</a></h2>
                           <ul>
                              <li><span class="linearicons-calendar-full"></span>6th Feb 2022</li>
                              <li><span class="linearicons-user"></span>Africa Can End Poverty</li>
                           </ul>
                           <p>Since the beginning of the COVID-19 pandemic, we have been exploring its effects in the Democratic Republic of Congo (DRC) through household high-frequency phone surveys implemented in Kinshasa, Kasai and Central Kasai, and East DRC (Beni, Bunia, Goma, and Lubero). </p>
                           <a href="https://blogs.worldbank.org/africacan/reversing-adverse-effects-covid-19-pandemic-democratic-republic-congo" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>

                     <div class="col-lg-4">
                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/thumbs_b_c_4e8e9ac05a4826b8c173f1a6a28752ac.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/thumbs_b_c_4e8e9ac05a4826b8c173f1a6a28752ac.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://www.aa.com.tr/en/africa/drc-sees-surge-in-covid-19-cases-official-says/2449616" target="_blank">DRC sees surge in COVID-19 cases, official says</a></h2>
                           <ul>
                              <li><span class="linearicons-calendar-full"></span>14th Dec 2021</li>
                              <li><span class="linearicons-user"></span>Godfrey Olukya</li>
                           </ul>
                           <p>UN radio in the Democratic Republic of Congo reported Thursday that the COVID-19 situation in the country is worsening.Dr. Jean-Jacques Muyembe Tamfum, the coordinator of the technical secretariat of the response team against COVID-19, announced that the situation is very explosive in the country, it said. </p>
                           <a href="https://www.aa.com.tr/en/africa/drc-sees-surge-in-covid-19-cases-official-says/2449616" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>
                     <div class="col-lg-4">

                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/nafdac.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/nafdac.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghana-faces-hurdles-to-achieve-targets-set-for-covid-19-vaccine-rollout-155075" target="_blank">COVID-19: NAFDAC approves two herbal drugs for clinical trial</a></h2>
                           <ul>
                              <li><span class="linearicons-calendar-full"></span>11th Jan 2022</li>
                              <li><span class="linearicons-user"></span>Nan</li>
                           </ul>
                           <p>The National Agency for Food and Drug Administration and Control (NAFDAC) says it has approved two herbal drugs to undergo clinical trials for the treatment of COVID-19.A statement on Sunday by the agency Resident Media Consultant, Mr Olusayo Akintola.</p>
                           <a href="https://theconversation.com/ghana-faces-hurdles-to-achieve-targets-set-for-covid-19-vaccine-rollout-155075" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>


                     


                     

                     
                     <!----------row-end---------->
                  </div>
                </div>
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
       
</template>